import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterQuickLinks from "./partials/FooterQuickLinks";
import FooterSocial from "./partials/FooterSocial";
import FooterAddress from "./partials/FooterAddress";

import { Container, Row, Col } from "reactstrap";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  settingObj,
  footerMenu,
  ...props
}) => {
  const classes = classNames(
    "site--footer site--footer-sm center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <>
      <footer {...props} className={classes}>
        <Container>
          <Row>
            <Col lg={6} className="col-12">
              <div className="footer--logo">
                <Logo imageUrl={settingObj.Logo ?? ""} />
              </div>
              <FooterAddress settingObj={settingObj} />
            </Col>

            <Col lg={4} className="col-12">
              <h4 className="footer--title">Popular Searches</h4>
              <FooterNav />
            </Col>

            <Col lg={2} className="col-12">
              {footerMenu && footerMenu.length > 0 ? (
                <>
                  <h4 className="footer--title">Quick Links</h4>
                  <FooterQuickLinks footerMenu={footerMenu} />
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </footer>
      <section className="site--footer__bottom site--footer__bottom-sm">
        <Container fluid={false}>
          <Row>
            <Col lg={8} className="col-12">
              <div className="footer-copyright">
                &copy; {new Date().getFullYear()} TatyanaCaruso. Designed By{" "}
                <a href="https://missio.io">Missio</a> All Rights Reserved.
              </div>
            </Col>
            <Col lg={4} className="col-12">
              <FooterSocial settingObj={settingObj} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
