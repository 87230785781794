import React from "react";
import { ClipLoader } from "react-spinners";

class LoaderComponent extends React.Component {
  render() {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClipLoader color="#E3C87D" loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we loading
          </div>
        </div>
      </>
    );
  }
}

export default LoaderComponent;
