import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterQuickLinks = ({ className, footerMenu, ...props }) => {
  const classes = classNames("footer--nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        {footerMenu.map((footerMenuObj) => (
          <li key={footerMenuObj.ID}>
            <Link target={footerMenuObj.target} to={footerMenuObj.url}>
              {footerMenuObj.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterQuickLinks;
