import React from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  //InputGroup,
} from "reactstrap";
import MlsProperty from "./../../api/MlsProperty";

import LoaderComponent from "./../LoaderComponent";
import FeaturesProperties from "./FeaturesProperties";

// Import UI Components
//import FindSearch from "../sections/FindSearch";

import Select from "react-select";

import { Formik } from "formik";
// Import Static icons
import { Search } from "react-feather";

const SubdivisionsOptions = [
    { value: "Boulder City", label: "Boulder City" },
    { value: "Caliente", label: "Caliente" },
    { value: "Ely", label: "Ely" },
    { value: "Henderson", label: "Henderson" },
    { value: "Las Vegas", label: "Las Vegas" },
    { value: "Mesquite", label: "Mesquite" },
    { value: "MacDonald Highlands", label: "MacDonald Highlands" },
    { value: "North Las Vegas", label: "North Las Vegas" },
    { value: "Summerlin", label: "Summerlin" }
];

const BedroomsOptions = [
  { value: "", label: "Any" },
  { value: "1", label: "1 +" },
  { value: "2", label: "2 +" },
  { value: "3", label: "3 +" },
  { value: "4", label: "4 +" },
  { value: "5", label: "5 +" },
];

const BathroomsOptions = [
  { value: "", label: "Any" },
  { value: "1", label: "1 +" },
  { value: "2", label: "2 +" },
  { value: "3", label: "3 +" },
  { value: "4", label: "4 +" },
  { value: "5", label: "5 +" },
];

const PriceOptions = [
  { value: "100000", label: "Under $100,000" },
  { value: "200000", label: "Under $200,000" },
  { value: "300000", label: "Under $300,000" },
  { value: "400000", label: "Under $400,000" },
  { value: "500000", label: "Under $500,000" },
  { value: "600000", label: "Under $600,000" },
  { value: "700000", label: "Under $700,000" },
  { value: "800000", label: "Under $800,000" },
  { value: "900000", label: "Under $900,000" },
  { value: "1000000", label: "Under $1,000,000" },
  { value: "1500000", label: "Under $1,500,000" },
  { value: "2000000", label: "Under $2,000,000" },
  { value: "3000000", label: "Under $3,000,000" },
  { value: "4000000", label: "Under $4,000,000" },
  { value: "5000000", label: "Under $5,000,000" },
  { value: "", label: "No Maximum Price" },
];

class DevelopmentsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialObj: {
        keyword: "",
        Subdivisions: "",
        beds: "",
        baths: "",
        price: "",
      },
      propertyList: [],
      propertyFetchLoader: false,
      showMoreLoader: false,
      SearchLoader: false,
      fetchListCount: 12,
      values: [],
      searchfilters: '',
    };
  }

  updateListCount = () => {
    this.setState(
      {
        fetchListCount: this.state.fetchListCount + 12,
      },
      () => {
        this.fetchPropertyList(this.state.searchfilters);
      }
    );
  };

  fetchPropertyList = async (formdata) => {
    try {
      if (this.state.fetchListCount > 12) {
        this.setState({
          showMoreLoader: true,
        });
      } else {
        this.setState({
          propertyFetchLoader: true,
        });
      }
      const response = await MlsProperty.getPropertyListLandingPage(
        this.state.fetchListCount,
        formdata
      );

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          propertyList:
            data.propertyList && data.propertyList.value
              ? data.propertyList.value
              : [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        propertyFetchLoader: false,
        showMoreLoader: false,
        SearchLoader: false,
      });
    }
  };

  componentDidMount() {
    // fetch property list;
    this.fetchPropertyList();
  }

  submitHandler = async (formdata, { resetForm }) => {
    try {
      console.log("Submit Search");
      this.setState({
        showLoader: true,
      });
      const formdatav = formdata;
      if (this.state.SubdivisionsVal && this.state.SubdivisionsVal.value) {
        formdata.Subdivisions = this.state.SubdivisionsVal.value;
      } else {
        formdata.Subdivisions = "";
      }
      if (this.state.bedsVal && this.state.bedsVal.value) {
        formdata.beds = this.state.bedsVal.value;
      } else {
        formdata.bedsVal = "";
      }
      if (this.state.bathsVal && this.state.bathsVal.value) {
        formdata.baths = this.state.bathsVal.value;
      } else {
        formdata.baths = "";
      }
      if (this.state.priceVal && this.state.priceVal.value) {
        formdata.price = this.state.priceVal.value;
      } else {
        formdata.price = "";
      }

      if (formdatav.house && formdatav.house.length) {
        formdata.house = "on";
      } else {
        formdata.house = "";
      }
      if (formdatav.condo && formdatav.condo.length) {
        formdata.condo = "on";
      } else {
        formdata.condo = "";
      }
        
      if (formdatav.Gated && formdatav.Gated.length) {
        formdata.Gated = "on";
      } else {
        formdata.Gated = "";
      }
      if (formdatav.Pool && formdatav.Pool.length) {
        formdata.Pool = "on";
      } else {
        formdata.Pool = "";
      }
        
      this.setState({
        searchfilters: formdata,
      });
        
      this.fetchPropertyList(formdata);
        
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  state = {
    SubdivisionsVal: null,
    bedsVal: null,
    bathsVal: null,
    priceVal: null,
  };

   handleChange = (SubdivisionsVal) => {
     if (SubdivisionsVal && SubdivisionsVal !== "") {
       this.setState({ SubdivisionsVal });
     } else {
       this.setState({ SubdivisionsVal: null });
     }
  };
  handleChange2 = (bedsVal) => {
    this.setState({ bedsVal });
  };
  handleChange3 = (bathsVal) => {
    this.setState({ bathsVal });
  };
  handleChange4 = (priceVal) => {
    this.setState({ priceVal });
  };
  render() {
    const { SubdivisionsVal } = this.state;
    const { bedsVal } = this.state;
    const { bathsVal } = this.state;
    const { priceVal } = this.state;

    return (
      <>
        <section className="features--properties features--properties-sm">
          <Container fluid className="container-fluid container-fluid-sm">
            <Row className="justify-content-start">
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className="find-search">
                  <h2 className="d-flex align-items-center mt-0 mb-0">
                    <Search size={20} className="mr-2" />
                    Quick Home Search
                  </h2>
                  <div className="find-search-inner">
                    <Formik
                      initialValues={this.state.initialObj}
                      onSubmit={this.submitHandler}
                    >
                      {({
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                         
                        <FormGroup>
                          <Label for="Subdivisions">Communities</Label>
                          <Select
                            id="Subdivisions"
                            name="Subdivisions"
                            type="select" 
                            options={SubdivisionsOptions}
                            value={SubdivisionsVal}
                            onChange={this.handleChange}
                            defaultValue="All Communities"
                            isClearable
                            isSearchable
                          />
                        </FormGroup>
                          <FormGroup className="row less-gutters">
                            <Col lg={6} sm={6} xs={6}>
                              <Label for="beds">Bedrooms</Label>
                              <Select
                                id="beds"
                                name="beds"
                                options={BedroomsOptions}
                                value={bedsVal}
                                onChange={this.handleChange2}
                                type="select"
                                defaultValue="3+"
                              />
                            </Col>
                            <Col lg={6} sm={6} xs={6}>
                              <Label for="baths">Bathrooms</Label>
                              <Select
                                id="baths"
                                name="baths"
                                options={BathroomsOptions}
                                value={bathsVal}
                                onChange={this.handleChange3}
                                type="select"
                                defaultValue="2+"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup>
                            <Label for="Price">Price</Label>
                            <Select
                              id="price"
                              name="price"
                              type="select"
                              options={PriceOptions}
                              value={priceVal}
                              onChange={this.handleChange4}
                              defaultValue="Under $1000000"
                            />
                          </FormGroup>

                          <Row className="row less-gutters mb-3">
                            <Col lg={6} sm={6} xs={6}>
                              <FormGroup
                                check
                                className="checkbox checkbox-primary"
                              >
                                <Input
                                  id="Gated"
                                  name="Gated"
                                  onChange={handleChange}
                                  type="checkbox"
                                />
                                <Label check for="Gated">
                                  Gated
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col lg={6} sm={6} xs={6}>
                              <FormGroup
                                check
                                className="checkbox checkbox-primary"
                              >
                                <Input
                                  id="Pool"
                                  name="Pool"
                                  onChange={handleChange}
                                  type="checkbox"
                                />
                                <Label check for="Pool">
                                  Pool
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
        
                          <Row className="row less-gutters mb-3">
                            <Col lg={6} sm={6} xs={6}>
                              <FormGroup
                                check
                                className="checkbox checkbox-primary"
                              >
                                <Input
                                  id="house"
                                  name="house"
                                  onChange={handleChange}
                                  type="checkbox"
                                />
                                <Label check for="house">
                                  House
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col lg={6} sm={6} xs={6}>
                              <FormGroup
                                check
                                className="checkbox checkbox-primary"
                              >
                                <Input
                                  id="condo"
                                  name="condo"
                                  onChange={handleChange}
                                  type="checkbox"
                                />
                                <Label check for="condo">
                                  Condo
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>

                          <FormGroup className="mb-0">
                            <button
                              type="submit"
                              className="c-button c-button-block c-button--gold c-button-md c-button-radius"
                              disabled={this.state.SearchLoader ? true : false}
                            >
                              Submit
                            </button>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={12} xs={12}>
                {this.state.propertyFetchLoader ? (
                  <LoaderComponent />
                ) : (
                  <>
                    <FeaturesProperties
                      {...this.props}
                      propertyList={this.state.propertyList}
                    />

                    <div
                      className={`${
                        this.state.showMoreLoader ? "properties--sold" : null
                      } mb-0`}
                    >
                      <Row className="justify-content-center mt-0 sm-margin-30px-top mb-5">
                        <Col lg={9} md={9} sm={12} xs={12} className="col-12">
                          <div className="w-100 d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="c-button c-button--black c-buttom-w200 border-radius-0 button-loading rounded-sm"
                              onClick={this.updateListCount}
                            >
                              Show More
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default DevelopmentsContent;
