import Api from "./Api";
import appConfig from "./../config";

export default class MlsProperty {
  static getPropertyListLandingPage = async (limit,formdata:[]) => {
    
    const postData = {
      cid: appConfig.CID,
      status: 'All',
      limit: limit,
      formdata:formdata
    };
    return await Api.postRequest(`/mls_fetch_property`, postData);
  };

  static getSoldPropertyListings = async (limit) => {
    const postData = {
      cid: appConfig.CID,
      status: 'Closed',
      limit: limit,
    };
    return await Api.postRequest(`/mls_fetch_property`, postData);
  };

  static getPropertyById = async (property_id) => {
    const postData = {
      cid: appConfig.CID,
      property_id: property_id,
    };
    return await Api.postRequest(`/mls_fetch_single_property`, postData);
  };
}
