import React from "react";

import HeroFAQsInner from "../components/sections/HeroFAQsInner";
import FAQsQuestionaire from "../components/sections/FAQsQuestionaire";

class FAQsScreen extends React.Component {
  render() {
    return (
      <div>
        <HeroFAQsInner />
        <FAQsQuestionaire />
      </div>
    );
  }
}

export default FAQsScreen;
