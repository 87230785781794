import React from 'react';
import Slider from 'react-slick';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {} from 'reactstrap';
import SP01 from '../../assets/images/SoldProperties/sp-01.jpg';
import SP02 from '../../assets/images/SoldProperties/sp-02.jpg';
import SP03 from '../../assets/images/SoldProperties/sp-03.jpg';
import SP04 from '../../assets/images/SoldProperties/sp-04.jpg';
import SP05 from '../../assets/images/SoldProperties/sp-05.jpg';
import SP06 from '../../assets/images/SoldProperties/sp-06.jpg';
class ReactSlickSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }

  render() {
    return (
      <div className="Modal--Slider">
        <Slider {...this.state}>
          <div>
            <img src={SP01} alt="Slide 01" />
          </div>
          <div>
            <img src={SP02} alt="Slide 02" />
          </div>
          <div>
            <img src={SP03} alt="Slide 03" />
          </div>
          <div>
            <img src={SP04} alt="Slide 04" />
          </div>
          <div>
            <img src={SP05} alt="Slide 05" />
          </div>
          <div>
            <img src={SP06} alt="Slide 06" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default ReactSlickSlider;
