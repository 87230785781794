import React from "react";
import { decode } from "html-entities";

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/* <!-- For Full Width Template --> */}
        {this.props.pageData && this.props.pageData.template_id === 3 ? (
          <div class="section--content section--content-sm">
            <div
              class="container"
              dangerouslySetInnerHTML={{
                __html: decode(
                  this.props.pageData && this.props.pageData.page_desc
                    ? this.props.pageData.page_desc
                    : ""
                ),
              }}
            ></div>
          </div>
        ) : null}

        {/* <!-- For Right Sidebar Template --> */}
        {this.props.pageData && this.props.pageData.template_id === 5 ? (
          <section class="section--content section--content-sm">
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-9 col-md-9 col-sm-12 col-xs-12 Page--Content--With--Right--Sidebar">
                  <div className="section--content__inner section--content__inner-sm">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decode(
                          this.props.pageData && this.props.pageData.page_desc
                            ? this.props.pageData.page_desc
                            : ""
                        ),
                      }}
                    ></div>
                  </div>
                </div>
                <aside class="col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  {/* <!-- Right Sidebar Code Comes Here --> */}
                  <div class="widget--sidebar">
                    <div class="widget margin-2-rem-bottom sm-margin-40px-top">
                      <h3
                        className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.rightsidebar &&
                              this.props.pageData.rightsidebar.sidebar_header1
                              ? this.props.pageData.rightsidebar.sidebar_header1
                              : ""
                          ),
                        }}
                      ></h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.rightsidebar &&
                              this.props.pageData.rightsidebar.sidebar_desc1
                              ? this.props.pageData.rightsidebar.sidebar_desc1
                              : ""
                          ),
                        }}
                      ></div>
                    </div>

                    <div class="widget margin-2-rem-bottom sm-margin-40px-top">
                      <h3
                        className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.rightsidebar &&
                              this.props.pageData.rightsidebar.sidebar_header2
                              ? this.props.pageData.rightsidebar.sidebar_header2
                              : ""
                          ),
                        }}
                      ></h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.rightsidebar &&
                              this.props.pageData.rightsidebar.sidebar_desc2
                              ? this.props.pageData.rightsidebar.sidebar_desc2
                              : ""
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        ) : null}

        {/* <!-- For Left Sidebar Template --> */}
        {this.props.pageData && this.props.pageData.template_id === 4 ? (
          <section class="section--content section--content-sm">
            <div class="container">
              <div class="row">
                <aside class="col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12 sm:flex-order-1">
                  {/* <!-- Left Sidebar Code Comes Here --> */}
                  <div class="widget--sidebar">
                    <div class="widget margin-2-rem-bottom sm-margin-40px-top">
                      <h3
                        className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.leftsidebar &&
                              this.props.pageData.leftsidebar.sidebar_header1
                              ? this.props.pageData.leftsidebar.sidebar_header1
                              : ""
                          ),
                        }}
                      ></h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.leftsidebar &&
                              this.props.pageData.leftsidebar.sidebar_desc1
                              ? this.props.pageData.leftsidebar.sidebar_desc1
                              : ""
                          ),
                        }}
                      ></div>
                    </div>

                    <div class="widget margin-2-rem-bottom sm-margin-40px-top">
                      <h3
                        className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.leftsidebar &&
                              this.props.pageData.leftsidebar.sidebar_header2
                              ? this.props.pageData.leftsidebar.sidebar_header2
                              : ""
                          ),
                        }}
                      ></h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: decode(
                            this.props.pageData &&
                              this.props.pageData.leftsidebar &&
                              this.props.pageData.leftsidebar.sidebar_desc2
                              ? this.props.pageData.leftsidebar.sidebar_desc2
                              : ""
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                </aside>
                <div class="col-12 col-lg-9 col-md-9 col-sm-12 col-xs-12 Page--Content--With--Left--Sidebar sm:flex-order-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decode(
                        this.props.pageData && this.props.pageData.page_desc
                          ? this.props.pageData.page_desc
                          : ""
                      ),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {/* <!-- For Left & Right Sidebar Template --> */}
        {this.props.pageData && this.props.pageData.template_id === 6 ? (
          <div class="container">
            <div class="row">
              <aside class="col-12 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                {/* <!-- Left Sidebar Code Comes Here --> */}
                <h3
                  className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.props.pageData &&
                        this.props.pageData.leftsidebar &&
                        this.props.pageData.leftsidebar.sidebar_header1
                        ? this.props.pageData.leftsidebar.sidebar_header1
                        : ""
                    ),
                  }}
                ></h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.props.pageData &&
                        this.props.pageData.leftsidebar &&
                        this.props.pageData.leftsidebar.sidebar_desc1
                        ? this.props.pageData.leftsidebar.sidebar_desc1
                        : ""
                    ),
                  }}
                ></div>
              </aside>

              <div class="col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.props.pageData && this.props.pageData.page_desc
                        ? this.props.pageData.page_desc
                        : ""
                    ),
                  }}
                ></div>
              </div>

              <aside class="col-12 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                {/* <!-- Right Sidebar Code Comes Here --> */}
                <h3
                  className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.props.pageData &&
                        this.props.pageData.rightsidebar &&
                        this.props.pageData.rightsidebar.sidebar_header1
                        ? this.props.pageData.rightsidebar.sidebar_header1
                        : ""
                    ),
                  }}
                ></h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.props.pageData &&
                        this.props.pageData.rightsidebar &&
                        this.props.pageData.rightsidebar.sidebar_desc1
                        ? this.props.pageData.rightsidebar.sidebar_desc1
                        : ""
                    ),
                  }}
                ></div>
              </aside>
            </div>
          </div>
        ) : null}

        {/* <!-- For Without Sidebar Template --> */}
        {this.props.pageData && this.props.pageData.template_id === 7 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: decode(
                this.props.pageData && this.props.pageData.page_desc
                  ? this.props.pageData.page_desc
                  : ""
              ),
            }}
          ></div>
        ) : null}

        {/* <!-- For Without Sidebar Template --> */}
        {(this.props.pageData && this.props.pageData.template_id === 1) ||
        (this.props.pageData && this.props.pageData.template_id === 2) ||
        (this.props.pageData && !this.props.pageData.template_id) ? (
          <div
            dangerouslySetInnerHTML={{
              __html: decode(
                this.props.pageData && this.props.pageData.page_desc
                  ? this.props.pageData.page_desc
                  : ""
              ),
            }}
          ></div>
        ) : null}
      </>
    );
  }
}

export default TemplateWrapper;
