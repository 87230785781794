import React from "react";
import { toast } from "react-toastify";
import { decode } from "html-entities";
import Helmet from "react-helmet";
import { Row, Col, Container } from "reactstrap";

// API import;
import Home from "../api/Home";
import MlsProperty from "./../api/MlsProperty";
import appConfig from "./../config";

import Hero from "../components/sections/Hero";
import HeroSlider from "../components/sections/HeroSlider";
import FeaturesProperties from "../components/sections/FeaturesProperties";
import LoaderComponent from "../components/LoaderComponent";

class LandingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {},
      slider: [],
      propertyList: [],
      showLoader: false,
      propertyFetchLoader: false,
    };
  }

  fetchPropertyList = async () => {
    try {
      this.setState({
        propertyFetchLoader: true,
      });
      const response = await MlsProperty.getPropertyListLandingPage();

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          propertyList:
            data.propertyList && data.propertyList.value
              ? data.propertyList.value
              : [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        propertyFetchLoader: false,
      });
    }
  };

  fetchHomeDetail = async () => {
    try {
      this.setState({
        showLoader: true,
      });
      const response = await Home.getHomePageContent();

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          pageData: data.pageData,
          slider: data.slider ? data.slider : [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  componentDidMount() {
    // fetch screen details;
    this.fetchHomeDetail();

    // fetch property list;
    this.fetchPropertyList();
  }

  render() {
    const pathname = this.props.location.pathname;
    const ogImage =
      this.state.slider && this.state.slider.length > 0
        ? `${appConfig.BANNER_PATH}/${appConfig.CID}/${this.state.slider[0].slider_image}`
        : "";
    return (
      <div>
        {this.state.showLoader || this.state.propertyFetchLoader ? (
          <LoaderComponent />
        ) : (
          <>
            <div className="application">
              <Helmet
                htmlAttributes={{ lang: "en", amp: undefined }} // amp takes no value
                title={this.state.pageData.meta_title}
                titleTemplate="%s"
                defaultTitle="Tatyana Caruso"
                meta={[
                  {
                    name: "description",
                    content: this.state.pageData.page_meta,
                  },
                  {
                    property: "og:title",
                    content: this.state.pageData.meta_title,
                  },
                  { property: "og:image", content: ogImage },
                  {
                    property: "og:description",
                    content: this.state.pageData.page_meta,
                  },
                  { property: "og:type", content: "website" },
                  {
                    property: "og:url",
                    content: `${appConfig.BASE_URL}${pathname}`,
                  },
                  { property: "og:site_name", content: "Tatyana Caruso" },
                ]}
              />
            </div>
            {this.state.slider && this.state.slider.length > 0 ? (
              this.state.slider[0] && this.state.slider[0].is_video === 1 ? (
                <Hero className="hero--banner" slider={this.state.slider[0]} />
              ) : (
                <HeroSlider slider={this.state.slider} />
              )
            ) : (
              <Hero className="hero--banner" slider={this.state.slider} />
            )}

            <section className="bg-white home--featured-lists">
              <Container fluid className="container-fluid-sm">
                <FeaturesProperties
                  {...this.props}
                  propertyList={this.state.propertyList}
                />
              </Container>
            </section>
            <div className="w-100 bg-loading-button bg-white pt-0">
              <Row className="bg-white pb-0 mb-5 justify-content-center mt-3 sm-margin-30px-top">
                <Col lg={6} md={6} sm={6} xs={12} className="col-12">
                  <div className="w-100 d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="c-button c-button--black c-buttom-w200 border-radius-0 button-loading"
                      onClick={() =>
                        this.props.history.push(`featured-listings`)
                      }
                    >
                      Show More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {this.state.pageData.page_desc ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: decode(this.state.pageData.page_desc),
                }}
              ></div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default LandingScreen;
