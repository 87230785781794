import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import { Link } from 'react-router-dom';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Logo from "./partials/Logo";
import HeaderNav from "./partials/HeaderNav";

// Import Static icons
import { X, Globe } from "react-feather";
import USFlag from "../../assets/images/flags/us.svg";
import ESFlag from "../../assets/images/flags/es.svg";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  settingObj,
  headerMenu,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header site-header-sm",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container-fluid container-fluid-sm">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo imageUrl={settingObj.Logo ?? ""} />
          {!hideNav && (
            <>
              <div className="ml-auto d-flex align-items-center">
                <a
                  className="header--number"
                  href={`tel:${settingObj.Phone_Number ?? ``}`}
                >
                  {settingObj.Phone_Number ?? ""}
                </a>
                <UncontrolledButtonDropdown className="ml-4">
                  <DropdownToggle>
                    <Globe size={20} className="find--icon" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <span className="size20">
                        <img src={USFlag} alt="English" />
                      </span>
                      <span className="text">English</span>
                    </DropdownItem>
                    <DropdownItem>
                      <span className="size20">
                        <img src={ESFlag} alt="Spanish" />
                      </span>
                      <span className="text">Spanish</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <div className="ml-3">
                  <button
                    ref={hamburger}
                    className="header-nav-toggle"
                    onClick={isActive ? closeMenu : openMenu}
                  >
                    <span className="screen-reader">Menu</span>
                    <span className="hamburger">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
              </div>
              <div
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <button className="header--close-btn" onClick={closeMenu}>
                  <X size={36} className="find--icon" />
                </button>
                <div className="header-nav--content">
                  <Logo imageUrl={settingObj.Logo ?? ""} />
                  <HeaderNav
                    closeMenu={closeMenu}
                    headerMenu={headerMenu}
                    settingObj={settingObj}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="menu--overlay" onClick={closeMenu}></div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
