import React from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import API
import MlsProperty from "./../../api/MlsProperty";

// Import Static icons
import { ChevronDown } from "react-feather";

import LoaderComponent from "./../LoaderComponent";
import SoldProperties from "./SoldProperties";

class SoldPropertiesListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyList: [],
      propertyFetchLoader: false,
      showMoreLoader: true,
      fetchListCount: 12,
    };
  }

  updateListCount = () => {
    this.setState(
      {
        fetchListCount: this.state.fetchListCount + 12,
      },
      () => {
        this.fetchPropertyList();
      }
    );
  };

  fetchPropertyList = async () => {
    try {
      if (this.state.fetchListCount > 12) {
        this.setState({
          showMoreLoader: true,
        });
      } else {
        this.setState({
          propertyFetchLoader: true,
        });
      }
      const response = await MlsProperty.getSoldPropertyListings(
        this.state.fetchListCount
      );

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          propertyList:
            data.propertyList && data.propertyList.value
              ? data.propertyList.value
              : [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        propertyFetchLoader: false,
        showMoreLoader: false,
      });
    }
  };

  componentDidMount() {
    // fetch property list;
    this.fetchPropertyList();
  }

  render() {
    return (
      <>
        <section className="features--properties features--properties-sm pt-5">
          {this.state.propertyFetchLoader ? (
            <LoaderComponent />
          ) : (
            <>
              <Container>
                <Row className="justify-content-between">
                  <Col lg={9} className="col-12">
                    <div className="section--content__inner section--content__inner-sm">
                      <div className="d-flex align-items-center justify-content-between margin-2-rem-bottom">
                        <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-0">
                          Sold Properties
                        </h3>
                      </div>

                      <div className="d-flex align-items-center justify-content-between margin-2-rem-bottom">
                        <div className="">110 Results</div>
                        <div className="ml-auto result--sorting">
                          <UncontrolledDropdown>
                            <DropdownToggle color="primary">
                              Sort
                              <ChevronDown size={12} className="ml-1" />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem>
                                Sold Price (High to Low)
                              </DropdownItem>
                              <DropdownItem>
                                Sold Price (Low to High)
                              </DropdownItem>
                              <DropdownItem>Status</DropdownItem>
                              <DropdownItem>City</DropdownItem>
                              <DropdownItem>Listing Date</DropdownItem>
                              <DropdownItem>Date Sold</DropdownItem>
                              <DropdownItem>
                                Type / Price Descending
                              </DropdownItem>
                              <DropdownItem>Listing Number</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>

                      <div className="properties--sold-lists mb-0">
                        <Row>
                          <SoldProperties
                            {...this.props}
                            propertyList={this.state.propertyList}
                          />
                        </Row>

                        <div
                          className={`${
                            this.state.showMoreLoader
                              ? "properties--sold"
                              : null
                          } mb-0`}
                        >
                          <Row className="justify-content-center mt-0 sm-margin-30px-top mb-5">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="col-12"
                            >
                              <div className="w-100 d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="c-button c-button--black c-buttom-w200 border-radius-0 button-loading rounded-sm"
                                  onClick={this.updateListCount}
                                >
                                  Show More
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={3} className="col-12">
                    <div className="margin-2-rem-bottom sm-margin-60px-top">
                      <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                        About
                      </h3>
                    </div>
                    <p>
                      It is with great pleasure that Luxury Estates
                      International welcomes you to Las Vegas &amp; Henderson,
                      NV and to{" "}
                      <a
                        className="link-blue"
                        href="https://www.tatyanaluxuryhomes.com"
                        title="tatyanaluxuryhomes"
                      >
                        www.tatyanaluxuryhomes.com
                      </a>
                      . With years of successful experience in the Luxury
                      Residential market, Tatyana Caruso knows how crucial it is
                      for you to find relevant, up-to-date information. The
                      search is over. This website is designed to be your-stop
                      shop for real estate in Southern Nevada.
                    </p>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </section>
      </>
    );
  }
}

export default SoldPropertiesListings;
