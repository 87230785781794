import Api from "./Api";
import appConfig from "./../config";

export default class Testimonial {
  static getTestimonial = async () => {
    return await Api.getRequest(
      `/get_testimonial_page_content?cid=${appConfig.CID}&get_testimonial_page_content=1`
    );
  };
}
