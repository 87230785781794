import React from "react";
import classNames from "classnames";

const FooterAddress = ({ className, settingObj, ...props }) => {
  const classes = classNames("footer--address", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        {settingObj.Address || settingObj.Address2 ? (
          <li>
            {settingObj.Address ?? null}
            {settingObj.Address2 ? (
              <>
                <br />
                {settingObj.Address2}
              </>
            ) : null}
          </li>
        ) : null}

        <li>{settingObj.Phone_Number ?? ""}</li>
        <li>
          <a href={`to:${settingObj.Contact_Email ?? ""}`}>Email Me »</a>
        </li>
      </ul>
    </div>
  );
};

export default FooterAddress;
