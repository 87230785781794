import React from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import FooterSocial from "./FooterSocial";

const HeaderNav = ({
  className,
  closeMenu,
  headerMenu,
  settingObj,
  ...props
}) => {
  const classes = classNames("header--nav", className);
  const { pathname } = useLocation();
  return (
    <nav {...props} className={classes}>
      <ul className="header--nav__lists">
        {headerMenu && headerMenu.length > 0
          ? headerMenu.map((headerMenuObj) => {
              const target =
                headerMenuObj.target === "_BLANK"
                  ? { target: headerMenuObj.target }
                  : {};
              const url =
                headerMenuObj.url.split("/")[0] === ``
                  ? headerMenuObj.url
                  : `/${headerMenuObj.url}`;
              const activeClass = pathname === url ? `is--active` : ``;
              return (
                <li key={headerMenuObj.ID}>
                  <NavLink
                    onClick={closeMenu}
                    to={url}
                    {...target}
                    className={`${activeClass}`}
                  >
                    {headerMenuObj.title}
                  </NavLink>
                  {headerMenuObj.childrenMenu.length > 0 ? (
                    <ul>
                      {headerMenuObj.childrenMenu.map((childMenuObj) => {
                        const targetChild =
                          childMenuObj.target === "_BLANK"
                            ? { target: childMenuObj.target }
                            : {};
                        const urlChild =
                          childMenuObj.url.split("/")[0] === ``
                            ? childMenuObj.url
                            : `/${childMenuObj.url}`;
                        const activeClassChild =
                          pathname === urlChild ? `is--active` : ``;
                        return (
                          <li key={childMenuObj.ID}>
                            <NavLink
                              onClick={closeMenu}
                              to={`${urlChild}`}
                              className={`${activeClassChild}`}
                              {...targetChild}
                            >
                              {childMenuObj.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })
          : null}
      </ul>
      <FooterSocial settingObj={settingObj} />
    </nav>
  );
};

export default HeaderNav;
