import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import BannerImage from "../../assets/images/ContactUs-BG.jpg";
import appConfig from "./../../config";

import Breadcrumb from "../elements/Breadcrumb";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  slider,
  ...props
}) => {
  const outerClasses = classNames(
    "hero--inner section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero--banner__body section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      {slider && slider.length > 0 ? (
        slider.map((obj) => {
          const bannerImage = obj.slider_image
            ? `${appConfig.BANNER_PATH}/${appConfig.CID}/${obj.slider_image}`
            : BannerImage;
          return (
            <div className={innerClasses}>
              <div className="hero--content">
                {/* <h1 className="mb-0">Contact Us</h1> */}
                <Breadcrumb {...props} />
              </div>
              <div className="hero-figure">
                <Image className="img-fluid" src={bannerImage} alt="Hero" />
              </div>
            </div>
          );
        })
      ) : (
        <div className={innerClasses}>
          <div className="hero--content">
            {/* <h1 className="mb-0">Contact Us</h1> */}
            <Breadcrumb {...props} />
          </div>
          <div className="hero-figure">
            <Image className="img-fluid" src={BannerImage} alt="Hero" />
          </div>
        </div>
      )}
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
