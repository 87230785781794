import React from "react";
import { Container, Row, Col } from 'reactstrap';

import Image from "../components/elements/Image";
import Page404BG from "../assets/images/Parallax/Page404BG.jpg";

// Import Static icons
import { Home, BookOpen } from "react-feather";

class Page404Screen extends React.Component {
  render() {
    return (
      <section className="page404">
        <div className="page404__body">
          <Container>
            <Row className="justify-content-center">
              <Col
                lg={8}
                className="col-12"
              >
                <div class="page404--content">
                  <h1 className="mb-0">ERROR 404 NOT FOUND</h1>
                  <p>You may have mis-typed the URL. Or the page has been removed.</p>
                  <p>Actually, there is nothing to see here...</p>
                  <p>Click on the links below to do something, Thanks!</p>
                  <div class="hero--buttons mb-4 mt-4">
                    <a className="c-button c-button--white" href="/"><Home size={18} className="mr-2" />Home</a>
                    <a className="c-button c-button--white" href="/contact-us"><BookOpen size={18} className="mr-2" />Contact Us</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div class="page404--overlay"></div>
        <div className="page404--figure">
          <Image className="img-fluid" src={Page404BG} alt="Page 404 Image" />
        </div>
      </section>
    );
  }
}

export default Page404Screen;
