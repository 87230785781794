import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Static icons
// import { PhoneCall, Mail } from "react-feather";

class DevelopmentsContentInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="contact--forms contact--forms-sm">
        <Container>
          <Row className="justify-content-between">
            <Col lg={9} className="col-12">
              <div className="section--content__inner section--content__inner-sm">
                <div className="margin-1-half-rem-bottom text-left">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-2">
                    {this.props.title.replace("\\n", "\n")}
                  </h3>
                </div>
                <Row className="justify-content-between">
                  <Col lg={12} className="col-12 sm-margin-30px-bottom">
                    <p>
                      Luxury courtyard living debuts at The Estates at Lone
                      Mountain! First residence for bold, new custom home
                      community is a striking, modern desert compound with an
                      eclectic series of outdoor spaces, including a pool, spa
                      and fire features. Entertainment wing dazzles with a
                      gourmet kitchen, game room and rooftop deck. Master
                      bedroom is a stylish retreat with a private courtyard,
                      outdoor shower, morning-kitchen and must-see closet.
                    </p>
                    <p>
                      Guests will love the large casita connected to the
                      five-car garage. Entire home comes with smart-home
                      technology. Large one-acre estate lot with its own private
                      gate offers space and security in a quiet, rustic
                      environment. Palm trees tower above large yards and lush
                      landscaping. No SIDs or LIDs. Turnkey-ready target date in
                      May 2021.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={3} className="col-12">
              <div className="margin-2-rem-bottom sm-margin-40px-top">
                <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                  About
                </h3>
              </div>
              <p>
                It is with great pleasure that Reality OneGroup International
                welcomes you to Las Vegas &amp; Henderson, NV and to
                oneluxehomeslasvegas.com. With years of successful experience in
                the Luxury Residential market, Tatyana Caruso knows how crucial
                it is for you to find relevant, up-to-date information. The
                search is over. This website is designed to be your-stop shop
                for real estate in Southern Nevada.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default DevelopmentsContentInner;
