const dev = {
  BASE_URL: "http://localhost:3000",
  API_URL: "http://localhost:4501/api",
  PORTAL_URL: "http://portal.local.missio.io/portal/",
  PORTAL_MAIN: "http://portal.local.missio.io/",
  EVENTS_HOST: "http://events.local.missio.io/",
  CID: 329,
  LOGO_PATH: "http://portal.local.missio.io/portal/files/logos",
  BANNER_PATH: "http://portal.local.missio.io/portal/newcms/files/slider",
};

const prod = {
  BASE_URL: "https://tatyanaluxuryhomes.com",
  API_URL: "https://api.missio.io/api/",
  PORTAL_URL: "https://admin.missio.io/portal/",
  PORTAL_MAIN: "https://admin.missio.io/",
  EVENTS_HOST: "http://events.local.missio.io/",
  CID: 329,
  LOGO_PATH: "https://admin.missio.io/portal/files/logos",
  BANNER_PATH: "https://admin.missio.io/portal/newcms/files/slider",
};

const uat = {
  BASE_URL: "https://tatyanaluxuryhomes.com",
  API_URL: "https://api.missio.io/api/",
  PORTAL_URL: "https://admin.missio.io/portal/",
  PORTAL_MAIN: "https://admin.missio.io/",
  EVENTS_HOST: "http://events.local.missio.io/",
  CID: 329,
  LOGO_PATH: "https://admin.missio.io/portal/files/logos",
  BANNER_PATH: "https://admin.missio.io/portal/newcms/files/slider",
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : dev;

const appConfig = {
  REACT_APP_STAGE: process.env.REACT_APP_STAGE.trim(),
  LOCAL_STORAGE_NAME: "tatyanacarusoStorage",
  API_KEY:
    "25442A472D4B6150645367566B59703373357638792F423F4528482B4D6251655468576D5A7134743777397A24432646294A404E635266556A586E3272357538",
  AWS_S3_NAME: "http://s3.missio.io/files/missio-products",
  ...config,
};

export default appConfig;
