import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import NumberFormat from "react-number-format";

import ReactSlickSlider from "../../components/sections/ReactSlickSlider";
import ReactGallerySlickSlider from "../../components/sections/ReactGallerySlickSlider";
import PropertiesDetailsTab from "../../components/sections/PropertiesDetailsTab";
import ShareThis from "../../components/sections/ShareThis";

// Static Assets
import Bed from "../../assets/images/icons/bed.svg";
import Bath from "../../assets/images/icons/bathtub.svg";
import Area from "../../assets/images/icons/area.svg";
import Type from "../../assets/images/icons/type.svg";

// Import Static icons
import { ArrowLeft, Map } from "react-feather";

class SoldPropertiesListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyDetail: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      isImageModalOpen: false,
    };
  }

  imageModalToggler = () => {
    this.setState({
      isImageModalOpen: !this.state.isImageModalOpen,
    });
  };

  render() {
    const propertyDetail = this.props.propertyDetail;
    return (
      <section
        className="section--content section--content-sm"
        style={{ backgroundColor: "#fff" }}
      >
        <Container>
          <Row className="justify-content-between">
            <Col lg={9} className="col-12">
              <div className="section--content__inner section--content__inner-sm">
                <div className="d-flex align-items-center justify-content-between margin-2-rem-bottom">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-0">
                    {propertyDetail.StreetNumber}{" "}
                    {propertyDetail.SubdivisionName}{" "}
                    {propertyDetail.DirectionFaces}, {propertyDetail.PostalCity}{" "}
                    {propertyDetail.PostalCode}
                  </h3>
                </div>
                <div className="d-flex align-items-center justify-content-between margin-1-half-rem-bottom">
                  <div className="">
                    <Link
                      className="d-inline-flex align-items-center link-primary text-sec"
                      to="/"
                    >
                      <ArrowLeft size={15} className="mr-1" />
                      Return To Results
                    </Link>
                  </div>
                  <div className="ml-auto result--sorting">
                    <ShareThis />
                  </div>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between margin-1-half-rem-bottom">
                  <ul className="lead--btns">
                    <li className="lead--btns__items">
                      <button
                        type="button"
                        className="d-inline-flex align-items-center text-sec u-button-pills"
                        onClick={this.imageModalToggler}
                      >
                        <Clock
                          size={14}
                          className="mr-1 d-none d-md-block d-lg-block"
                        />
                        Schedule Showing
                      </button>
                    </li>
                    <li className="lead--btns__items">
                      <button
                        type="button"
                        className="d-inline-flex align-items-center text-sec u-button-pills"
                        onClick={this.imageModalToggler}
                      >
                        <Mail
                          size={14}
                          className="mr-1 d-none d-md-block d-lg-block"
                        />
                        Request Info
                      </button>
                    </li>
                    <li className="lead--btns__items">
                      <button
                        type="button"
                        className="d-inline-flex align-items-center text-sec u-button-pills"
                        onClick={this.imageModalToggler}
                      >
                        <Heart
                          size={14}
                          className="mr-1 d-none d-md-block d-lg-block"
                        />
                        Save To Favorites
                      </button>
                    </li>
                  </ul>
                </div> */}

                <div className="properties--sold mb-0">
                  <Row className="align-items-center justify-content-between mb-3">
                    <Col lg={8} mb={8} className="col-12">
                      <h4 class="d-flex fs-20">
                        <Map
                          size={20}
                          className="mr-2"
                          style={{ marginTop: "3px" }}
                        />
                        {propertyDetail.StreetNumber}{" "}
                        {propertyDetail.SubdivisionName} <br />
                        {propertyDetail.DirectionFaces},{" "}
                        {propertyDetail.PostalCity} {propertyDetail.PostalCode}
                      </h4>
                    </Col>
                    <Col lg={4} mb={4} className="col-12">
                      <h4 class="fs-20 md-text-right">
                        <span class="ihf-for-sale-price">
                          <NumberFormat
                            value={propertyDetail.OriginalListPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </span>
                      </h4>
                    </Col>
                  </Row>

                  <div className="list-fx-features start p-0 mb-3">
                    <div className="list-fx-features-info">
                      <div className="list-fx-features-info-icon">
                        <img src={Bed} alt="Bed" width="14" />
                      </div>
                      {propertyDetail.BedroomsTotal ?? 0} Beds
                    </div>
                    <div className="list-fx-features-info">
                      <div className="list-fx-features-info-icon">
                        <img src={Bath} alt="Bath" width="14" />
                      </div>
                      {propertyDetail.BathroomsTotalInteger ?? 0} Bath
                    </div>
                    <div className="list-fx-features-info">
                      <div className="list-fx-features-info-icon">
                        <img src={Area} alt="Area" width="14" />
                      </div>
                      {propertyDetail.LotSizeSquareFeet} sqft
                    </div>
                    {propertyDetail.ZoningDescription && (
                      <div className="list-fx-features-info">
                        <div className="list-fx-features-info-icon">
                          <img src={Type} alt="Type" width="14" />
                        </div>
                        {propertyDetail.ZoningDescription}
                      </div>
                    )}
                  </div>

                  {/* Gallery */}
                  <div className="properties--details__gallery">
                    <ReactGallerySlickSlider
                      mediaList={propertyDetail.Media}
                      {...this.props}
                    />
                    <div className="mt-4 w-100">
                      Listing #{propertyDetail.ListingKey}
                    </div>
                  </div>

                  <PropertiesDetailsTab
                    description={propertyDetail.PublicRemarks}
                    propertyId={propertyDetail.ListingKey}
                  />

                  <div className="sub--title with-border-bottom mb-4">
                    <h4 class="d-flex fs-22 fs-md-26">Property Details</h4>
                  </div>

                  <Row className="align-items-center justify-content-between mb-3">
                    <Col lg={4} mb={4} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">Price:</label>
                        <span className="property--specs__excerpt">
                          <NumberFormat
                            value={propertyDetail.OriginalListPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </span>
                      </div>
                    </Col>
                    <Col lg={3} mb={3} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">Beds:</label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.BedroomsTotal ?? 0}
                        </span>
                      </div>
                    </Col>
                    <Col lg={5} mb={5} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">Type:</label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.ZoningDescription}
                        </span>
                      </div>
                    </Col>

                    <Col lg={4} mb={4} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Home Size:
                        </label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.LotSizeSquareFeet}
                        </span>
                      </div>
                    </Col>
                    <Col lg={3} mb={3} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">Baths:</label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.BathroomsTotalInteger ?? 0}
                        </span>
                      </div>
                    </Col>
                    <Col lg={5} mb={5} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Garage:
                        </label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.GarageSpaces ?? 0} Car Garage
                        </span>
                      </div>
                    </Col>

                    <Col lg={4} mb={4} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Lot Size:
                        </label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.LotSizeSquareFeet}
                        </span>
                      </div>
                    </Col>
                    <Col lg={3} mb={3} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">Year:</label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.YearBuilt}
                        </span>
                      </div>
                    </Col>
                    <Col lg={5} mb={5} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Neighborhood:
                        </label>
                        <span className="property--specs__excerpt">
                          Anthem Country Club
                        </span>
                      </div>
                    </Col>
                    <Col lg={12} mb={12} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Community Features:
                        </label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.CommunityFeatures} 
                        </span>
                      </div>
                    </Col>
                    <Col lg={12} mb={12} className="col-12">
                      <div className="property--specs">
                        <label className="property--specs__label">
                          Association Amenities:
                        </label>
                        <span className="property--specs__excerpt">
                          {propertyDetail.AssociationAmenities} 
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col lg={3} className="col-12">
              <div className="margin-2-rem-bottom sm-margin-60px-top">
                <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                  About
                </h3>
              </div>
              <p>
                It is with great pleasure that Reality OneGroup International
                welcomes you to Las Vegas &amp; Henderson, NV and to
                oneluxehomeslasvegas.com. With years of successful experience in
                the Luxury Residential market, Tatyana Caruso knows how crucial
                it is for you to find relevant, up-to-date information. The
                search is over. This website is designed to be your-stop shop
                for real estate in Southern Nevada.
              </p>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.isImageModalOpen}
            toggle={this.imageModalToggler}
            className="modal--medium modal--medium-md"
            backdrop={true}
          >
            <ModalHeader
              className="start plr--30 plr--30-sm"
              toggle={this.imageModalToggler}
            >
              681 Dragon Peak Drive, Henderson, NV 89012
            </ModalHeader>
            <ModalBody
              className="plr--30 plr--30-sm"
              style={{ maxHeight: "inherit" }}
            >
              <div className="mt-2 mb-2">
                <ReactSlickSlider {...this.props} />
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </section>
    );
  }
}

export default SoldPropertiesListings;
