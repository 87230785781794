import React from "react";
// import classNames from "classnames";
import NumberFormat from "react-number-format";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SectionTilesProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

// import SectionHeader from "./partials/SectionHeader";

// Static Assets
import Properties01 from "../../assets/images/properties01-700x355.jpg";
import Bed from "../../assets/images/icons/bed.svg";
import Bath from "../../assets/images/icons/bathtub.svg";
import Area from "../../assets/images/icons/area.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const SoldProperties = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  propertyList,
  ...props
}) => {
  return (
    <>
      {propertyList && propertyList.length > 0 ? (
        <>
          {propertyList.map((obj) => {
            const image =
              obj.PhotosCount > 0
                ? obj.Media && obj.Media.length > 0
                  ? obj.Media[0].MediaURL
                  : Properties01
                : Properties01;
            return (
              <Col lg={6} md={6} sm={6} xs={12} className="col-12">
                <div
                  className="features--properties-item-inner mb-5"
                  onClick={() => {
                    props.history.push(`/properties-details/${obj.ListingKey}`);
                  }}
                  key={obj.ListingKey}
                >
                  <div className="features--properties__price mb-0">
                    <NumberFormat
                      value={obj.OriginalListPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                  <div className="p-3 w-100">
                    <Link
                      to="/properties-details"
                      className="features--properties-item-image mb-0"
                    >
                      <LazyLoadImage
                        effect="blur"
                        alt="Cooperate"
                        src={image}
                        className="img-fluid"
                      />
                    </Link>
                    <div className="features--properties-item-content text-center">
                      <p className="description">
                        {obj.StreetNumber} {obj.SubdivisionName}{" "}
                        {obj.DirectionFaces}, {obj.PostalCity} {obj.PostalCode}
                      </p>
                    </div>
                    <div className="list-fx-features p-0">
                      <div className="list-fx-features-info">
                        <div className="list-fx-features-info-icon">
                          <img src={Bed} alt="Bed" width="14" />
                        </div>
                        {obj.BedroomsTotal ?? 0}bd
                      </div>
                      <div className="list-fx-features-info">
                        <div className="list-fx-features-info-icon">
                          <img src={Bath} alt="Bath" width="14" />
                        </div>
                        {obj.BathroomsFull ?? 0}ba
                      </div>
                      <div className="list-fx-features-info">
                        <div className="list-fx-features-info-icon">
                          <img src={Area} alt="Area" width="14" />
                        </div>
                        {obj.LotSizeSquareFeet} Sq. Ft.
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </>
      ) : null}
    </>
  );
};

SoldProperties.propTypes = propTypes;

SoldProperties.defaultProps = defaultProps;

export default SoldProperties;
