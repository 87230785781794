import React from "react";
import { Container, Row, Col } from "reactstrap";

import Faq from "react-faq-component";

// Import Static icons
import { ChevronDown } from "react-feather";

const data = {
  title: "",
  rows: [
    {
      title: "What the first step of the home buying process?",
      content: (
        <>
          <p>
            Getting pre-approved for a mortgage is the first step of the home
            buying process. Getting a pre-approval letter from a lender get the
            ball rolling in the right direction.
          </p>
          <p>Here’s why:</p>
          <p>
            First, you need to know how much you can borrow. Knowing how much
            home you can afford narrows down online home searching to suitable
            properties, thus no time is wasted considering homes that are not
            within your budget. (Pre-approvals also help prevent disappointment
            caused by falling in love unaffordable homes.)
          </p>
          <p>
            Second, the loan estimate from your lender will show how much money
            is required for the down payment and closing costs. You may need
            more time to save up money, liquidate other assets or seek mortgage
            gift funds from family. In any case, you will have a clear picture
            of what is financially required.
          </p>

          <p>
            Finally, being pre-approved for a mortgage demonstrates that you are
            a serious buyer to both your real estate agent and the person
            selling their home.
          </p>

          <p>
            Most real estate agents will require a pre-approval before showing
            homes - this is especially true at the higher end of the real estate
            market; sellers of luxury homes will only allow pre-screened (and
            verified) buyers to view their homes. This is meant to keep out
            "Looky Lous" and protect the seller’s privacy. What’s more, by
            limiting who enters their home, sellers are given extra security
            from potential thieves trying to case the home (like identifying
            security systems, locating expensive artwork or other high-value
            personal property).
          </p>
        </>
      ),
    },
    {
      title: "How long does it take to buy a home?",
      content: (
        <>
          <p>
            From start (searching online) to finish (closing escrow), buying a
            home takes about 10 to 12 weeks. Once a home is selected an the
            offer is accepted, the average time to complete the escrow period on
            a home is 30 to 45 days (under normal market conditions). Though,
            well-prepared home buyers who pay cash have been known to purchase
            properties faster than that.
          </p>
          <p>
            Market conditions are a major factor in how fast homes are sold. In
            hot markets with a lot of sales activity, buying a home may take a
            little longer than normal. That’s because several parties involved
            in the transaction get behind when business suddenly picks up. For
            example, a spike in home sales increases the demand for property
            appraisals and home inspections, yet there will be no increase in
            the number of appraisers and inspectors available to do the work.
            Lender turn-around times for loan underwriting can also slow down.
            If each party involved in a deal takes a day or two longer to get
            their work done, the entire process gets extended.
          </p>
        </>
      ),
    },
    {
      title: "What is a seller’s market?",
      content: (
        <>
          <p>
            In sellers’ markets, increasing demand for homes drives up prices.
            Here are some of the drivers of demand:
          </p>
          <ul>
            <li>
              <strong>Economic factors</strong> – the local labor market heats
              up, bringing an inflow of new residents and pushing up home prices
              before more inventory can be built.
            </li>
            <li>
              <strong>Interest rates trending downward</strong> – improves home
              affordability, creating more buyer interest, particularly for{" "}
              <a
                href="/blog/first-time-home-buyer-california/"
                title="first time home buyer"
                data-uw-rm-brl="false"
              >
                first time home buyers
              </a>{" "}
              who can afford bigger homes as the cost of money goes lower.
            </li>
            <li>
              <strong>A short-term spike in interest rates</strong> - may compel
              “on the fence” buyers to make a purchase if they believe the
              upward trend will continue. Buyers want to make a move before
              their purchasing power (the amount they can borrow) gets eroded.
            </li>
            <li>
              <strong>Low inventory</strong> - fewer homes on the market because
              of a lack of new construction. Prices for existing homes may go up
              because there are fewer units available.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is a buyer’s market?",
      content: (
        <>
          <p>
            A buyer’s market is characterized by declining home prices and
            reduced demand. Several factors may affect long-term and short-term
            buyer demand, like: Economic disruption - a big employer shuts down
            operations, laying off their workforce.
          </p>
          <ul>
            <li>
              <strong>Interest rates trending higher</strong> – the amount of
              money the people can borrow to buy a home is reduced because the
              cost of money is higher, thus reducing the total number of
              potential buyers in the market. Home prices drop to meet the level
              of demand and buyers find better deals.
            </li>
            <li>
              <strong>Short-term drop in interest rates</strong> – can give
              borrowers a temporary edge with more purchasing power before home
              prices can react to the recent interest rate changes.
            </li>
            <li>
              <strong>High inventory</strong> – a new subdivision and can create
              downward pressure on prices of older homes nearby, particularly if
              they lack highly desirable features (modern appliances, etc.)
            </li>
            <li>
              <strong>Natural disasters</strong> - a recent earthquake or
              flooding can tank property values in the neighborhood where those
              disruptions occurred.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is a stratified market?",
      content: (
        <>
          <p>
            A stratified market happens where supply and demand characteristics
            differ by price point, in the same area (typically by city). For
            example, home sales for properties above $1.5M may be brisk
            (seller’s market) while homes under $750k may be sluggish (buyer’s
            market). This scenario comes along every so often in West Coast
            cities where international investors - looking to park their money
            in the United States - buy expensive real estate. At the same time,
            home sales activity in mid-priced homes could be entirely different.
          </p>
        </>
      ),
    },
  ],
};

const styles = {
  // bgColor: "white",
  // titleTextColor: "blue",
  // rowTitleColor: "blue",
  // rowContentColor: "grey",
  // arrowColor: "red",
};

const config = {
  animate: true,
  arrowIcon: <ChevronDown size={20} color="#000" />,
  tabFocus: true,
};

class FAQsQuestionaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="section--content section--content-sm">
        <Container>
          <Row>
            <Col col={12} lg={9}>
              <div className="section--content__inner section--content__inner-sm">
                <div className="margin-1-half-rem-bottom text-left">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-2">
                    Frequently Asked Questions (How it works)
                  </h3>
                </div>

                <div className="faq--page">
                  <Faq data={data} styles={styles} config={config} />
                </div>
              </div>
            </Col>

            <Col col={12} lg={3}>
              <div className="widget--sidebar">
                <div className="widget margin-2-rem-bottom sm-margin-40px-top">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                    About Us
                  </h3>
                  <div>
                    <p>
                      It is with great pleasure that Reality OneGroup
                      International welcomes you to Las Vegas &amp; Henderson,
                      NV and to oneluxehomeslasvegas.com. With years of
                      successful experience in the Luxury Residential market,
                      Tatyana Caruso knows how crucial it is for you to find
                      relevant, up-to-date information. The search is over. This
                      website is designed to be your-stop shop for real estate
                      in Southern Nevada.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default FAQsQuestionaire;
