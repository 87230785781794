import axiosInstance from "../utils/AppAxios";

export default class Api {
  static postRequest = async (url, postData = {}) => {
    const response = await axiosInstance.post(url, postData);
    return response;
  };

  static getRequest = async (url) => {
    const response = await axiosInstance.get(url);
    return response;
  };
}
