import React from "react";
// import { Link } from "react-router-dom";
import Slider from "react-slick";
import { decode } from "html-entities";

import appConfig from "./../../config";

import ParallaxImage from "../../assets/images/Banner/01.jpg";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider({ slider, ...props }) {
  var settings = {
    dots: true,
    fade: true,
    speed: 4000,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <div className="hero--banner">
      <Slider {...settings}>
        {slider && slider.length > 0
          ? slider.map((obj) => (
              <div key={obj.ID}>
                <div className="hero--content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decode(
                        obj.slider_title || "HELPING YOU BUY &amp; SELL"
                      ),
                    }}
                  ></div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: decode(obj.slider_desc || "Luxury Homes"),
                    }}
                  ></div>
                  {/* <div className="hero--buttons">
                    <Link className="c-button c-button--white" to="/">
                      Find A Home
                    </Link>
                    <Link className="c-button c-button--white" to="/">
                      Sell A Home
                    </Link>
                  </div> */}
                </div>
                <img
                  src={
                    obj.slider_image
                      ? `${appConfig.BANNER_PATH}/${appConfig.CID}/${obj.slider_image}`
                      : ParallaxImage
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
            ))
          : null}
      </Slider>
    </div>
  );
}
