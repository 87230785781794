import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer--nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/seller">Property for Sale</Link>
        </li>
        <li>
          <Link to="/featured-listings">Featured Listings</Link>
        </li>
        <li>
          <Link to="/sold-properties">Sold Properties</Link>
        </li>
        <li>
          <Link to="/vegas-luxury-report">Vegas Luxury Report</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
