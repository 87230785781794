import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import LogoImage from "./../../../assets/images/logo-golden.png";

const Logo = ({ className, imageUrl, ...props }) => {
  const classes = classNames("page--logo page--logo-md", className);

  return (
    <div {...props} className={classes}>
      <Link to="/">
        <Image
          src={imageUrl ? imageUrl : LogoImage}
          srcSet={`${imageUrl ? imageUrl : LogoImage} 300w, ${
            imageUrl ? imageUrl : LogoImage
          } 768w, ${imageUrl ? imageUrl : LogoImage} 1280w`}
          alt="TatyanaCaruso"
          width={220}
        />
      </Link>
    </div>
  );
};

export default Logo;
