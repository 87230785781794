import React from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Static Images
import SP01 from "../../assets/images/properties01-700x355.jpg";

class ReactSlickSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dots: false,
      infinite: true,
      autoplay: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settings = {
      dots: false,
      speed: 1000,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      swipeToSlide: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const mediaList = this.props.mediaList;

    return (
      <div className="properties--gallery">
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          infinite={true}
          autoplay={true}
          className="gallery--slider"
        >
          {mediaList && mediaList.length > 0 ? (
            mediaList.map((obj) => {
              return (
                <div key={obj.Order}>
                  <img
                    src={obj.MediaURL}
                    alt={`${obj.OriginatingSystemName}-${obj.Order}`}
                  />
                </div>
              );
            })
          ) : (
            <div>
              <img src={SP01} alt={`Slider-01`} />
            </div>
          )}
        </Slider>

        <Slider
          {...settings}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          className="gallery--slider__thumbs"
        >
          {mediaList && mediaList.length > 0 ? (
            mediaList.map((obj) => {
              return (
                <div key={obj.Order}>
                  <img
                    src={obj.MediaURL}
                    alt={`${obj.OriginatingSystemName}-${obj.Order}`}
                  />
                </div>
              );
            })
          ) : (
            <div>
              <img src={SP01} alt={`Slider-01`} />
            </div>
          )}
        </Slider>
      </div>
    );
  }
}

export default ReactSlickSlider;
