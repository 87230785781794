import React from "react";
import { toast } from "react-toastify";
import { decode } from "html-entities";
// import Helmet from "react-helmet";

// Import APIS;
import Testimonial from "./../api/Testimonial";

import HeroTestimonials from "../components/sections/HeroTestimonials";
import FeaturedTestimonialsMore from "../components/sections/FeaturedTestimonialsMore";
import LoaderComponent from "../components/LoaderComponent";

class TestimonialScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      slider: [],
      testimonialList: [],
    };
  }

  getStructuredTestimonialData = async (array) => {
    let returnData = [];

    if (array && array.length > 0) {
      returnData = array.map((obj) => {
        if (obj.Description) {
          obj.Description = decode(obj.Description);
        }

        return obj;
      });
    }

    return returnData;
  };

  fetchTestimonials = async () => {
    try {
      this.setState({
        showLoader: true,
      });
      const response = await Testimonial.getTestimonial();

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          testimonialList: data.tetimonial_list,
          slider: data.slider && data.slider.length > 0 ? [data.slider[0]] : [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  componentDidMount() {
    //fetch testimonials list.
    this.fetchTestimonials();
  }

  render() {
    return (
      <div>
        {this.state.showLoader ? (
          <LoaderComponent />
        ) : (
          <>
            <HeroTestimonials
              {...this.props}
              slider={this.state.slider}
              className="testimonial--hero__banner"
            />
            <FeaturedTestimonialsMore
              testimonialList={this.state.testimonialList}
            />
          </>
        )}
      </div>
    );
  }
}

export default TestimonialScreen;
