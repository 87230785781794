import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

// API import;
import Setting from "./../api/Setting";
import appConfig from "./../config";

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

const Layout = ({ children }) => {
  useEffect(() => {
    const header = document.getElementById("appHeader");
    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("header--sticky");
        } else {
          header.classList.remove("header--sticky");
        }
      });
    }
  }, []);

  const [settingObj, setSettingObj] = useState({});
  const [headerMenu, setHeaderMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);

  const fetchSettings = async () => {
    try {
      const settings = await Setting.getSettings();
      if (!settings.data.error) {
        const settingObj =
          settings.data.data.length > 0 ? settings.data.data[0] : {};
        if (settingObj.Logo) {
          settingObj.Logo = `${appConfig.LOGO_PATH}/${settingObj.CID}/${settingObj.Logo}`;
        }

        setSettingObj(settingObj);
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMenu = async () => {
    try {
      const menus = await Setting.getMenu();
      if (!menus.data.error) {
        const menusObj = menus.data.data;

        setHeaderMenu(menusObj);
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFooterMenu = async () => {
    try {
      const footerMenus = await Setting.getFooterMenu();
      if (!footerMenus.data.error) {
        const footerMenusObj = footerMenus.data.data;
        setFooterMenu(footerMenusObj);
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (settingObj && !settingObj.CID) {
      fetchSettings();
      fetchMenu();
      fetchFooterMenu();
    }
  }, [settingObj, headerMenu, footerMenu]);

  return (
    <>
      <Header
        id="appHeader"
        navPosition="right"
        className={`reveal-from-bottom`}
        settingObj={settingObj}
        headerMenu={headerMenu}
      />
      <div style={{ marginTop: "0px" }}></div>
      <main className="site-content">{children}</main>
      <Footer settingObj={settingObj} footerMenu={footerMenu} />
    </>
  );
};

export default withRouter(Layout);
