import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

const PropertiesDetailsTab = ({ description, propertyId, ...props }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="properties--details mb-5">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Description
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Floor Plan
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <p>{description ?? "N/A"}</p>
              <p>Property ID: {propertyId ?? "N/A"}</p>
            </Col>
          </Row>
        </TabPane>

        {/* <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <h4 className="fs-20">Floor 1</h4>
              <figure className="meet--expert__image mb-0">
                <LazyLoadImage
                  effect="blur"
                  alt="Floor 1"
                  src={FloorPlan01}
                  className="img-fluid"
                />
              </figure>

              <h4 className="fs-20">Floor 2</h4>
              <figure className="meet--expert__image mb-0">
                <LazyLoadImage
                  effect="blur"
                  alt="Floor 2"
                  src={FloorPlan02}
                  className="img-fluid"
                />
              </figure>

              <h4 className="fs-20">Casita</h4>
              <figure className="meet--expert__image mb-0">
                <LazyLoadImage
                  effect="blur"
                  alt="Casita"
                  src={Casita}
                  className="img-fluid"
                />
              </figure>
            </Col>
          </Row>
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default PropertiesDetailsTab;
