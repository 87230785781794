import React from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import ShowMoreText from "react-show-more-text";
import { decode } from "html-entities";

class FeaturedTestimonialsMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="section--content section--content-sm">
        <Container>
          <Row>
            <Col col={12} lg={9}>
              <div className="section--content__inner section--content__inner-sm">
                <div className="margin-1-half-rem-bottom text-left">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-2">
                    Testimonials
                  </h3>
                </div>

                {this.props.testimonialList &&
                this.props.testimonialList.length > 0 ? (
                  this.props.testimonialList.map((obj) => (
                    <div
                      key={obj.ID}
                      className="featured--testimonials featured--testimonials-sm pt-0 pb-0"
                    >
                      <div className="featured--testimonials-item w--100">
                        <div className="featured--testimonials-item-inner">
                          <div className="testimonials-bubble">
                            <ShowMoreText
                              /* Default options */
                              lines={3}
                              more="Read More &raquo;"
                              less="Read Less &raquo;"
                              className="content-css"
                              anchorClass="my-anchor-css-class"
                              expanded={false}
                              width={400}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: decode(obj.Description),
                                }}
                              ></div>
                            </ShowMoreText>
                          </div>

                          <div className="author">
                            <div className="d-inline-flex align-items-center w-100 pt-2">
                              <span className="text-uppercase">
                                {obj.Title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Alert color="warning">No Testimonials Available</Alert>
                )}
              </div>
            </Col>

            <Col col={12} lg={3}>
              <div className="widget--sidebar">
                <div className="widget margin-2-rem-bottom sm-margin-40px-top">
                  <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                    About Us
                  </h3>
                  <div>
                    <p>
                      It is with great pleasure that Luxury Estates
                      International welcomes you to Las Vegas &amp; Henderson,
                      NV and to{" "}
                      <a
                        className="link-blue"
                        href="https://www.tatyanaluxuryhomes.com"
                        title="tatyanaluxuryhomes"
                      >
                        www.tatyanaluxuryhomes.com
                      </a>
                      . With years of successful experience in the Luxury
                      Residential market, Tatyana Caruso knows how crucial it is
                      for you to find relevant, up-to-date information. The
                      search is over. This website is designed to be your-stop
                      shop for real estate in Southern Nevada.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default FeaturedTestimonialsMore;
