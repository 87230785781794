import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Route } from "react-router";
import Layout from "../layout/Layout";

const AppRoute = ({
  component: Component,
  layout: ComponentLayout = Layout,
  ...rest
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Get the URL and add class to body as per URL change;
    const getPathName = pathname;
    let convertToClassSuffix =
      getPathName === "/" ? "" : getPathName.split("/").join("");
    convertToClassSuffix = `${convertToClassSuffix}`;
    document.body.className = convertToClassSuffix;
  }, [pathname]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <ComponentLayout {...props}>
          <Component {...props} />
        </ComponentLayout>
      )}
    />
  );
};

export default AppRoute;
