import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppRoute from "./components/AppRoute";

// Import Layout
import Layout from "./layout/Layout";
import "./assets/scss/default.scss";

// Home Page
import LandingScreen from "./pages/LandingScreen";
import TestimonialScreen from "./pages/TestimonialScreen";
import ContactScreen from "./pages/ContactScreen";
import FAQsScreen from "./pages/FAQsScreen";

// import AboutUsScreen from "./pages/AboutUsScreen";
// import ExceptionaMarketingScreen from "./pages/ExceptionaMarketingScreen";
import SoldPropertiesScreen from "./pages/SoldPropertiesScreen";
import PropertiesDetailScreen from "./pages/PropertiesDetailScreen";
import DevelopmentScreen from "./pages/DevelopmentScreen";
import FindAHomeScreen from "./pages/FindAHomeScreen";
import FeaturedListingsScreen from "./pages/FeaturedListingsScreen";
// import SellHomeScreens from "./pages/SellHomeScreens";
// import VegasLuxuryReportScreen from "./pages/VegasLuxuryReportScreen";

import CmsPage from "./pages/CmsPage";
import Page404Screen from "./pages/Page404Screen";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <AppRoute path="/" exact component={LandingScreen} layout={Layout} />
          <AppRoute
            path="/testimonials"
            exact
            component={TestimonialScreen}
            layout={Layout}
          />
          <AppRoute
            path="/contact-us"
            exact
            component={ContactScreen}
            layout={Layout}
          />
          <AppRoute path="/faqs" exact component={FAQsScreen} layout={Layout} />
          <AppRoute
            path="/sold-properties"
            exact
            component={SoldPropertiesScreen}
            layout={Layout}
          />
          <AppRoute
            path="/properties-details/:property_id"
            exact
            component={PropertiesDetailScreen}
            layout={Layout}
          />
          <AppRoute
            path="/developments"
            exact
            component={DevelopmentScreen}
            layout={Layout}
          />
          <AppRoute
            path="/find"
            exact
            component={FindAHomeScreen}
            layout={Layout}
          />
          <AppRoute
            path="/featured-listings"
            exact
            component={FeaturedListingsScreen}
            layout={Layout}
          />
          {/* <AppRoute
            path="/vegas-luxury-report"
            exact
            component={VegasLuxuryReportScreen}
            layout={Layout}
          />
          <AppRoute
            path="/seller"
            exact
            component={SellHomeScreens}
            layout={Layout}
          /> */}
          {/* <AppRoute
            path="/about-us"
            exact
            component={AboutUsScreen}
            layout={Layout}
          /> */}
          {/* <AppRoute
            path="/exceptional-marketing"
            exact
            component={ExceptionaMarketingScreen}
            layout={Layout}
          /> */}
          <AppRoute
            path="/:pageSlug"
            exact
            component={CmsPage}
            layout={Layout}
          />
          <AppRoute path="*" exact component={Page404Screen} layout={Layout} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
