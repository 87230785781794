import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import { decode } from "html-entities";

import appConfig from "./../../config";
import { SectionProps } from "../../utils/SectionProps";

// import Image from "../elements/Image";
import BannerImage from "../../assets/images/ContactUs-BG.jpg";
import Breadcrumb from "../elements/Breadcrumb";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const CmsSlider = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  slider,
  location,
  match,
  history,
  ...props
}) => {
  const settings = {
    dots: true,
    fade: true,
    speed: 4000,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  const outerClasses = classNames(
    "hero--inner section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero--banner__body section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero--inner__content">
          <Breadcrumb location={location} history={history} match={match} />
        </div>
        <div className="hero-figure">
          <Slider {...settings}>
            {slider && slider.length > 0
              ? slider.map((obj) => (
                  <div key={obj.ID}>
                    <div className="hero--content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decode(obj.slider_title),
                        }}
                      ></div>
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: decode(obj.slider_desc),
                        }}
                      ></div>
                    </div>
                    <img
                      src={
                        obj.slider_image
                          ? `${appConfig.BANNER_PATH}/${appConfig.CID}/${obj.slider_image}`
                          : BannerImage
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                ))
              : null}
          </Slider>
        </div>
      </div>
    </section>
  );
};

CmsSlider.propTypes = propTypes;
CmsSlider.defaultProps = defaultProps;

export default CmsSlider;
