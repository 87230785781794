import axios from "axios";
import { toast } from "react-toastify";
import appConfig from "./../config";

const axiosInstance = axios.create({
  baseURL: appConfig.API_URL,
});

axiosInstance.interceptors.request.use(function (request) {
  request.headers.apiKey = appConfig.API_KEY;
  return request;
});

// Add error response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      toast.error("Please check your internet connection.");
    } else {
      let message = error.response.statusText;
      let code = 0;
      //exception
      if (error.response.data && error.response.data.message) {
        message = error.response.data.message;
        code = error.response.data.code;
      }

      if (code === 900) {
        toast.error(message, {
          autoClose: 4000,
        });
      } else {
        toast.error(message);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
