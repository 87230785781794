import React from "react";
import { Row, Col } from "reactstrap";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

// Static Assets
import Properties01 from "../../assets/images/properties01-700x355.jpg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesProperties = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  propertyList,
  ...props
}) => {
  const outerClasses = classNames(
    "w-100 pt-0 bg-white pb-0 mb-0",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features--properties-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "features--properties-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Featured Properties",
  };

  return (
    <div {...props} className={outerClasses}>
      <div className={innerClasses}>
        <Row className="mb-4">
          <Col lg={12} md={12} className="col-12">
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              {propertyList && propertyList.length > 0 ? (
                <>
                  {propertyList.map((obj) => {
                    const image =
                      obj.PhotosCount > 0
                        ? obj.Media && obj.Media.length > 0
                          ? obj.Media[0].MediaURL
                          : Properties01
                        : Properties01;
                    return (
                      <div
                        className="features--properties-item reveal-from-bottom"
                        onClick={() => {
                          props.history.push(
                            `/properties-details/${obj.ListingKey}`
                          );
                        }}
                        key={obj.ListingKey}
                      >
                        <div className="features--properties-item-inner">
                          <figure className="features--properties-item-image mb-0">
                            <LazyLoadImage
                              effect="blur"
                              alt="Cooperate"
                              src={image}
                              className="img-fluid"
                            />
                          </figure>
                          <div className="features--properties-item-content">
                            <h4 className="price">
                              <NumberFormat
                                value={obj.OriginalListPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </h4>
                            <p className="description">
                              {obj.StreetNumber} {obj.SubdivisionName}{" "}
                              {obj.DirectionFaces}, {obj.PostalCity}{" "}
                              {obj.PostalCode}
                            </p>
                            <p className="specs">
                              {obj.BedroomsTotal ?? 0}bd /{" "}
                              {obj.BathroomsFull ?? 0}ba /{" "}
                              {obj.LotSizeSquareFeet} Sq. Ft.
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

FeaturesProperties.propTypes = propTypes;

FeaturesProperties.defaultProps = defaultProps;

export default FeaturesProperties;
