import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  PinterestShareButton,
  PinterestIcon
} from "react-share";

import exampleImage from '../../assets/images/logo-golden.png';

class ShareThis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const shareUrl = 'http://oneluxehomeslasvegas.missio.io/';
    const title = 'OneLuxe Homes Las Vegas';

    return (
      <div>
        <div className="d-flex align-items-center">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="sharethis__share-button ml-1"
          >
            <FacebookIcon size={32} />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            url={shareUrl}
            appId="521270401588372"
            className="sharethis__share-button ml-1"
          >
            <FacebookMessengerIcon size={32} />
          </FacebookMessengerShareButton>
          
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="sharethis__share-button ml-1"
          >
            <TwitterIcon size={32} />
          </TwitterShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="sharethis__share-button ml-1"
          >
            <EmailIcon size={32} />
          </EmailShareButton>

          <PinterestShareButton
            url={String(window.location)}
            media={`${String(window.location)}/${exampleImage}`}
            className="sharethis__share-button ml-1"
          >
            <PinterestIcon size={32} />
          </PinterestShareButton>
        </div>
      </div>
    );
  }
}

export default ShareThis;
