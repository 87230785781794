import Api from "./Api";
import appConfig from "./../config";

export default class Setting {
  static getSettings = async () => {
    return await Api.getRequest(`/get_settings?cid=${appConfig.CID}`);
  };

  static getMenu = async () => {
    return await Api.getRequest(`/get_menu?cid=${appConfig.CID}`);
  };

  static getFooterMenu = async () => {
    return await Api.getRequest(`/get_footer?cid=${appConfig.CID}`);
  };

  static getSliderByCategoryId = async (categoryId) => {
    return await Api.getRequest(
      `/get_slider?cid=${appConfig.CID}&slider_category=${categoryId}`
    );
  };
}
