import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

// Import APIS;
import Contact from "./../../api/Contact";

// Import Static icons
import { PhoneCall, Mail } from "react-feather";
import { toast } from "react-toastify";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Enter valid email address")
    .required("This field is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
  message: Yup.string().required("This field is required"),
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialObj: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      showLoader: false,
      showSuccessDiv: false,
    };
  }

  submitHandler = async (values, { resetForm }) => {
    try {
      this.setState({
        showLoader: true,
      });
      
      const response = await Contact.submitForm(values);
      if (!response.data.error) {
        resetForm();
        this.setState({
          showSuccessDiv: true,
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  render() {
    return (
      <section className="contact--forms contact--forms-sm">
        <Container>
          <Row className="justify-content-between">
            <Col lg={6} className="col-12">
              {this.state.showSuccessDiv ? (
                <Alert color="success">
                  <p className="mb-0">Thanks for contacting us</p>
                </Alert>
              ) : (
                <>
                  <div className="margin-2-half-rem-bottom">
                    <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-22 fs-md-32 mb-4">
                      Contact Us
                    </h3>

                    <h4 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-0">
                      We would love to hear from you!
                    </h4>
                    <p>Send us a message and we’ll get right back in touch.</p>
                  </div>

                  <div className="margin-2-half-rem-bottom">
                    <ul className="contact--details">
                      <li>
                        <PhoneCall size={18} className="mr-2" />{" "}
                        <a
                          className=""
                          href={`tel:${
                            this.props.setting
                              ? this.props.setting.Phone_Number
                              : ""
                          }`}
                        >
                          {this.props.setting
                            ? this.props.setting.Phone_Number
                            : ""}
                        </a>
                      </li>
                      <li>
                        <Mail size={18} className="mr-2" />{" "}
                        <a
                          className=""
                          href={`mailto:${
                            this.props.setting
                              ? this.props.setting.Contact_Email
                              : ""
                          }`}
                        >
                          {this.props.setting
                            ? this.props.setting.Contact_Email
                            : ""}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <p className="mb-2">Required fields are marked *</p>
                  <Formik
                    initialValues={this.state.initialObj}
                    validationSchema={validationSchema}
                    onSubmit={this.submitHandler}
                  >
                    {({
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md={6}>
                            <FormGroup
                              className={`mb-3 ${
                                errors.name && touched.name
                                  ? `form-group--has-error`
                                  : ``
                              }`}
                            >
                              <Label for="name" className="sr-only">
                                Name
                              </Label>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="help-block"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup
                              className={`mb-3 ${
                                errors.email && touched.email
                                  ? `form-group--has-error`
                                  : ``
                              }`}
                            >
                              <Label for="email" className="sr-only">
                                Email
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="help-block"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row form>
                          <Col md={6}>
                            <FormGroup
                              className={`mb-3 ${
                                errors.phone && touched.phone
                                  ? `form-group--has-error`
                                  : ``
                              }`}
                            >
                              <Label for="phone" className="sr-only">
                                Phone
                              </Label>
                              <Input
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="help-block"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup
                          className={`${
                            errors.message && touched.message
                              ? `form-group--has-error`
                              : ``
                          }`}
                        >
                          <Label for="message" className="sr-only">
                            Message
                          </Label>
                          <Input
                            type="textarea"
                            name="message"
                            id="message"
                            className="big"
                            placeholder="Message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="help-block"
                          />
                        </FormGroup>

                        <button
                          type="submit"
                          className="c-button c-button--black c-buttom-w120 border-radius-0"
                          disabled={this.state.showLoader ? true : false}
                        >
                          Submit
                        </button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Col>

            <Col lg={3} className="col-12">
              <div className="margin-2-rem-bottom sm-margin-40px-top">
                <h3 className="alt-font font-weight-800 text-extra-dark-gray fs-20 fs-md-26 mb-4">
                  About
                </h3>
              </div>
              <p>
                It is with great pleasure that Luxury Estates International
                welcomes you to Las Vegas &amp; Henderson, NV and to{" "}
                <a
                  className="link-blue"
                  href="https://www.tatyanaluxuryhomes.com"
                  title="tatyanaluxuryhomes"
                >
                  www.tatyanaluxuryhomes.com
                </a>
                . With years of successful experience in the Luxury Residential
                market, Tatyana Caruso knows how crucial it is for you to find
                relevant, up-to-date information. The search is over. This
                website is designed to be your-stop shop for real estate in
                Southern Nevada.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default ContactForm;
