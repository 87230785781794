import React from "react";
import classNames from "classnames";

// Import Static icons
import { Facebook, Twitter, Linkedin, Instagram } from "react-feather";

const FooterSocial = ({ className, settingObj, ...props }) => {
  const classes = classNames("footer--social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        {settingObj && settingObj.facebook ? (
          <li>
            <a
              href={`https://${settingObj.facebook ?? ``}`}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook size={22} className="find--icon" />
            </a>
          </li>
        ) : null}

        {settingObj && settingObj.twitter ? (
          <li>
            <a
              href={`https://${settingObj.twitter ?? ``}`}
              target="_blank"
              rel="noreferrer"
            >
              <Twitter size={22} className="find--icon" />
            </a>
          </li>
        ) : null}

        {settingObj && settingObj.LinkedIn ? (
          <li>
            <a
              href={`https://${settingObj.LinkedIn ?? ``}`}
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin size={22} className="find--icon" />
            </a>
          </li>
        ) : null}

        {settingObj && settingObj.instagram ? (
          <li>
            <a
              href={`https://${settingObj.instagram ?? ``}`}
              target="_blank"
              rel="noreferrer"
            >
              <Instagram size={22} className="find--icon" />
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default FooterSocial;
