import React from "react";
import { toast } from "react-toastify";
import Helmet from "react-helmet";

// API import;
import Home from "../api/Home";
import appConfig from "./../config";

import Hero from "../components/sections/Hero";

import CmsSlider from "../components/sections/CmsSlider";
import LoaderComponent from "../components/LoaderComponent";
import Page404Screen from "./Page404Screen";
import TemplateWrapper from "./TemplateWrapper";

class CmsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      pageSlug: "",
      pageData: {},
      slider: [],
    };
  }

  fetchPageContent = async () => {
    try {
      this.setState({
        showLoader: true,
      });
      const response = await Home.getCrmPage(this.state.pageSlug);

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          pageData: data.pageData ?? {},
          slider: data.slider ?? [],
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const pageSlug = this.props.location.pathname.split("/").pop();
    if (prevState.pageSlug !== this.state.pageSlug) {
      //fetch page content;
      this.fetchPageContent();
    }

    if (pageSlug !== this.state.pageSlug) {
      this.setState({
        pageSlug,
      });
    }
  }

  componentDidMount() {
    const pageSlug = this.props.location.pathname.split("/").pop();

    this.setState({
      pageSlug,
    });
  }

  render() {
    const pathname = this.props.location.pathname;
    const ogImage =
      this.state.slider && this.state.slider.length > 0
        ? `${appConfig.BANNER_PATH}/${appConfig.CID}/${this.state.slider[0].slider_image}`
        : "";
    return (
      <>
        {this.state.showLoader ? (
          <LoaderComponent />
        ) : this.state.pageData.ID ? (
          <>
            <div className="application">
              <Helmet
                htmlAttributes={{ lang: "en", amp: undefined }} // amp takes no value
                title={this.state.pageData.meta_title}
                titleTemplate="%s"
                defaultTitle="Tatyana Caruso"
                meta={[
                  {
                    name: "description",
                    content: this.state.pageData.page_meta,
                  },
                  {
                    property: "og:title",
                    content: this.state.pageData.meta_title,
                  },
                  { property: "og:image", content: ogImage },
                  {
                    property: "og:description",
                    content: this.state.pageData.page_meta,
                  },
                  { property: "og:type", content: "website" },
                  {
                    property: "og:url",
                    content: `${appConfig.BASE_URL}${pathname}`,
                  },
                ]}
              />
            </div>
            {this.state.slider && this.state.slider.length > 0 ? (
              <CmsSlider
                {...this.props}
                className="testimonial--hero__banner"
                slider={this.state.slider}
              />
            ) : (
              <Hero className="hero--banner" slider={this.state.slider} />
            )}
            {/* <HeroInner className="testimonial--hero__banner" /> */}
            <TemplateWrapper pageData={this.state.pageData} />
          </>
        ) : (
          <Page404Screen />
        )}
      </>
    );
  }
}

export default CmsPage;
