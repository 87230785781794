import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { SectionProps } from "../../utils/SectionProps";
import VideoPoster from "../../assets/video/video.mp4";
import VideoPosterImg from "../../assets/images/VideoPoster.jpg";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  slider,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero--banner__body section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero--content">
          <h4>
            {" "}
            {slider && slider.slider_title
              ? slider.slider_title
              : "HELPING YOU BUY & SELL"}{" "}
          </h4>
          <h1 className="">
            {" "}
            {slider && slider.slider_desc
              ? slider.slider_desc
              : "Luxury Homes"}{" "}
          </h1>
          <div className="hero--buttons">
            <Link className="c-button c-button--white" to="/find">
              Find A Home
            </Link>
            <Link className="c-button c-button--white" to="/seller">
              Sell A Home
            </Link>
          </div>
        </div>
        <div className="video-block">
          <video
            controls={false}
            loop={true}
            muted={true}
            autoPlay={true}
            poster={VideoPosterImg}
            src={VideoPoster}
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
