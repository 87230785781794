import React from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends React.Component {
  render() {
    let breadcrumbArray = [];
    const pageSlugObj = this.props.location.pathname.split("/");
    breadcrumbArray = pageSlugObj
      .map((obj, key) => {
        if (key === 0) return null;
        let newObj = {
          url: `/${obj}`,
          title: obj
            .toLowerCase()
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        };
        return newObj;
      })
      .filter((data) => {
        return data;
      });
    return (
      <div className="page--breadcrumb w-100">
        <div className="container">
          <div className="page--breadcrumb__inner">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              {breadcrumbArray && breadcrumbArray.length > 0
                ? breadcrumbArray.map((data, key) => {
                    const printUrl = data.url;
                    return breadcrumbArray.length - 1 === key ? (
                      <li key={printUrl}>{data.title}</li>
                    ) : (
                      <li key={printUrl}>
                        <Link to={printUrl}>{data.title}</Link>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
