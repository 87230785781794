import React from "react";
import { toast } from "react-toastify";

// Import APIS;
import Setting from "./../api/Setting";

import HeroTestimonials from "../components/sections/HeroTestimonials";
import DevelopmentsContentInner from "../components/sections/DevelopmentsContentInner";
import LoaderComponent from "../components/LoaderComponent";

class DevelopmentScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      slider: [],
      setting: {},
    };
  }

  getSliderDetail = async () => {
    try {
      this.setState({
        showLoader: true,
      });
      const settingRes = await Setting.getSettings();
      const response = await Setting.getSliderByCategoryId(3);

      if (!response.data.error) {
        const data = response.data.data;
        this.setState({
          slider: data && data.length > 0 ? [data[0]] : [],
          setting:
            settingRes.data.data.length > 0 ? settingRes.data.data[0] : {},
        });
      } else {
        toast.error("Please try again", {
          autoClose: 4000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  componentDidMount() {
    // fetch slider from backend;
    this.getSliderDetail();
  }

  render() {
    return (
      <div>
        {this.state.showLoader ? (
          <LoaderComponent />
        ) : (
          <>
            <HeroTestimonials
              {...this.props}
              slider={this.state.slider}
              className="testimonial--hero__banner"
            />
            <DevelopmentsContentInner title="Developments" />
          </>
        )}
      </div>
    );
  }
}

export default DevelopmentScreen;
