import Api from "./Api";
import appConfig from "./../config";

export default class Home {
  static getHomePageContent = async () => {
    return await Api.getRequest(
      `/get_cms_page_content?cid=${appConfig.CID}&home_page_flag=1`
    );
  };

  static getSlider = async () => {
    return await Api.getRequest(`/get_slider?cid=${appConfig.CID}`);
  };

  static getCrmPage = async (pageSlug) => {
    return await Api.getRequest(
      `/get_cms_page_content?cid=${appConfig.CID}&page_name=${pageSlug}`
    );
  };
}
