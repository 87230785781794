import React from "react";

import Image from "../elements/Image";
import BannerImage from "../../assets/images/Parallax/FAQsBg.jpg";

class HeroFAQsInner extends React.Component {
  render() {
    return (
      <div>
        <section className="hero--inner section testimonial--hero__banner">
          <div className="hero--banner__body section-inner">
            <div className="hero--inner__content" style={{ zIndex: 3 }}>
              <div className="page--breadcrumb w-100">
                <div className="container">
                  <div className="page--breadcrumb__inner">
                    <ul className="breadcrumb">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>FAQs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-figure">
              <div className="hero--content"></div>
              <Image className="img-fluid" src={BannerImage} alt="Hero" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HeroFAQsInner;
